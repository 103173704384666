<script>
import { ref, computed } from 'vue'
import { formatDollar } from '/~/utils/format/money'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseCheckbox from '/~/components/base/checkbox/base-checkbox.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import CouponTag from '/~/components/checkout/components/checkout-coupon-tag.vue'
import { useCheckout } from '/~/composables/checkout'

export default {
  name: 'checkout-coupons',
  components: {
    BaseCheckbox,
    BaseInput,
    BaseButton,
    CouponTag,
  },
  setup() {
    const {
      selectedCoupons,
      verifyPromotionCodeCurrentOrder,
      removeCartPromotionCode,
      isCouponVerifying,
      hasCoupons,
      payment,
    } = useCheckout()

    const useCoupons = ref(hasCoupons.value)
    const couponInput = ref('')

    const isDisabled = computed(() => {
      return payment.submitting || isCouponVerifying.value
    })

    async function onCouponAdd() {
      await verifyPromotionCodeCurrentOrder(couponInput.value)

      couponInput.value = ''
    }

    async function onCouponRemove(coupon) {
      await removeCartPromotionCode(coupon.promotionCode)

      useCoupons.value = hasCoupons.value
    }

    return {
      useCoupons,
      couponInput,
      isDisabled,
      selectedCoupons,
      isCouponVerifying,
      hasCoupons,
      onCouponAdd,
      onCouponRemove,
      formatDollar,
    }
  },
}
</script>

<template>
  <div class="space-y-5">
    <base-checkbox
      v-model="useCoupons"
      data-testid="checkout-coupons-standard"
      :disabled="hasCoupons"
      look="v2"
    >
      Use coupon code
    </base-checkbox>

    <div v-if="useCoupons && !hasCoupons" class="flex items-center rounded">
      <base-input
        v-model="couponInput"
        placeholder="Coupon code"
        nolabel
        autofocus
        :disabled="isDisabled"
      >
        <template #inner-filter>
          <div class="px-5">
            <base-button
              size="xl"
              look="link"
              :loading="isCouponVerifying"
              :disabled="isDisabled || !couponInput"
              @click="onCouponAdd"
            >
              <span
                class="font-normal"
                :class="{
                  'text-eonx-neutral-600': isDisabled || !couponInput,
                }"
              >
                Apply
              </span>
            </base-button>
          </div>
        </template>
      </base-input>
    </div>
    <div v-show="hasCoupons" class="flex flex-wrap">
      <coupon-tag
        v-for="(item, idx) in selectedCoupons"
        :key="idx"
        class="mb-2.5 mr-[5px]"
        @remove="onCouponRemove(item)"
      >
        {{ item.promotionCode }} {{ formatDollar(-item.amount) }}
      </coupon-tag>
    </div>
  </div>
</template>
